export const PRIMARY = '#3498db'

export const PRIMARY_500 = PRIMARY

export const GRAY_200 = '#eee'

export const PRIMARY_LIGHT = '#bedef3'

const PRIMARIES = {
  PRIMARY,
  PRIMARY_LIGHT: '#bedef3',
  PRIMARY_500: PRIMARY,
  PRIMARY_800: '#1b6698',
  PRIMARY_DARK: '#1b6698',
}

const GRAYS = {
  GRAY_200: '#eee',
  GRAY_500: '#999',
  GRAY_800: '#444',
}

export const COLOR = {
  ...PRIMARIES,
  ...GRAYS,
}
