import { Link } from 'gatsby';
import React from 'react';
import styled from '@emotion/styled';
import {
  PRIMARY,
  MAX_CONTENT_WIDTH,
  MAX_TABLET_WIDTH,
  HEADER_SMALL_SCREEN,
  HEADER_LARGE_SCREEN,
} from '../../constants';
import iconSrc from '../../images/icon-transparent.svg';

interface Props {
  siteTitle: string;
}

const Container = styled.header`
  background: ${PRIMARY};
  margin-bottom: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  h1:first-of-type {
    margin: 0;
    display: flex;
    align-items: center;
  }
`;
const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: ${MAX_CONTENT_WIDTH};
  padding: 0.5rem 1rem;
  height: ${HEADER_SMALL_SCREEN};

  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    padding: 1.5rem 2rem;
    height: ${HEADER_LARGE_SCREEN};
  }
`;

const Settings = styled.div`
  display: flex;
  img {
    height: 20px;
    width: auto;
    background: none;
  }
`;

const Icon = styled.img`
  height: 2rem;
  background: none;
  margin-left: 1rem;
  position: relative;
  bottom: -4px;
`;

interface Props {
  siteTitle: string;
}

export const Header = ({ siteTitle }: Props) => (
  <Container>
    <Inner>
      <h1>
        <Link
          to="/"
          style={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          {siteTitle}
          <Icon src={iconSrc} />
        </Link>
      </h1>
    </Inner>
  </Container>
);

export default Header;
